@import '../../../styles/vars';

$min-width: 800px;

.estimate {
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  margin-right: auto;
  height: 220px;
  width: 100%;
  justify-content: space-evenly;
  max-width: 1400px;

  .meeting__hands {
    > svg {
      width: 45%;
      height: auto;
    }
  }

  @media only screen and (max-width: 1250px) {
    margin: 0;
    justify-content: space-between;
  }

  @media only screen and (max-width: $min-width) {
    flex-direction: column-reverse;
    height: unset;
    align-items: center;

    .meeting__hands {
      margin-bottom: 20px;
      margin-top: 0px;
      width: 80%;

      > svg {
        width: 40%;
        height: auto;
      }
    }
  }

  @media only screen and (min-width: $min-width + 1) and (max-width: 1100px) {
    .meeting__hands {
      > svg {
        width: 40%;
        height: auto;
      }
    }
  }

  &__entry {
    position: relative;
    width: 320px;
    min-width: 280px;
    max-width: 320px;

    @media only screen and (max-width: $min-width) {
      width: 100%;
      margin-right: auto;
      margin-left: auto;
    }

    &__help {
      max-width: 400px;
      margin-bottom: 40px;

      @media only screen and (max-width: $min-width) {
        margin-bottom: 20px;
      }

      @media only screen and (max-width: $mobile-width) {
        display: none;
      }
    }

    &__lock {
      background: rgba(246, 248, 250, 0);
      opacity: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
      width: calc(100% + 40px);
      height: calc(100% + 20px);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      font-size: 60px;
      color: #264653;
      left: -20px;
      top: -10px;
      transition: all 0.5s ease-in-out;

      @media only screen and (max-width: $min-width) {
        left: -10px;
        top: -10px;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
      }

      &--visible {
        opacity: 1;
        pointer-events: unset;
        background: rgba(246, 248, 250, 0.9);
      }
    }

    &__quick-btns {
      display: flex;
      justify-content: space-between;

      > button {
        width: 40px;
        height: 40px;
        min-height: 40px;
        min-width: 40px;
        border-radius: 50%;
        padding: 0;

        @media only screen and (max-width: $mobile-width) {
          width: 50px;
          height: 50px;
          min-height: 50px;
          min-width: 50px;
          font-size: 18px;
        }
      }

      &--extended {
        > button {
          width: 30px;
          height: 30px;
          min-height: 30px;
          min-width: 30px;
          font-size: 13px;

          @media only screen and (max-width: $mobile-width) {
            width: 40px;
            height: 40px;
            min-height: 40px;
            min-width: 40px;
            font-size: 16px;
          }
        }
      }
    }

    &__input {
      display: flex;
      align-items: center;
      font-size: 40px;
      color: $primary-green;
      margin-top: 20px;
      justify-content: space-between;

      @media only screen and (max-width: $mobile-width) {
        font-size: 50px;
      }

      > input {
        width: calc(120px + (2 * ((100% - 200px) / 4)));
      }

      &__arrow {
        cursor: pointer;

        &:hover {
          color: lighten($primary-green, 5%);
        }

        &--disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }
}