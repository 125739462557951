@import '../../../styles/vars';

.share-modal {
  &__field {
    display: flex;

    > button {
      margin-left: 20px;
    }

    &:first-of-type {
      margin-bottom: 40px;

      @media only screen and (max-width: $mobile-width) {
        margin-bottom: 20px;
      }
    }
  }
}