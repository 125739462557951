@import '../../../styles/vars';

$min-width: 1230px;

.countdown {
  margin: auto;
  display: flex;
  align-items: center;

  > p {
    max-width: 350px;
    text-align: left;
    margin-left: 40px;

    @media
    only screen and (max-width: $min-width),
    only screen and (max-height: 750px) {
      margin-left: 0;
      margin-top: 40px;
      text-align: center;
    }

    @media only screen and (max-height: 750px) {
      margin-top: 0;
    }
  }

  @media
  only screen and (max-width: $min-width),
  only screen and (max-height: 750px) {
    flex-direction: column;
  }

  > svg {
    height: 200px;
    width: auto;

    @media
    only screen and (max-width: $min-width),
    only screen and (max-height: 750px) {
      height: 125px;
    }

    @media only screen and (max-height: 750px) {
      display: none;
    }
  }

  &--counting {
    @extend .countdown;
    animation: ease-in-out pulse 1s infinite;

    > h1 {
      font-size: 200px;

      @media
      only screen and (max-width: $min-width),
      only screen and (max-height: 750px) {
        font-size: 100px;
      }
    }
  }

  &__waiting {
    margin-left: 100px;

    @media
    only screen and (max-width: $min-width),
    only screen and (max-height: 750px) {
      margin-left: 0;
      text-align: center;
    }

    > p {
      max-width: 350px;

      @media
      only screen and (max-width: $min-width),
      only screen and (max-height: 750px) {
        margin-top: 20px;
      }

      @media only screen and (max-height: 750px) {
       margin-top: 0;
      }
    }

    > button {
      margin-top: 20px;

      @media
      only screen and (max-width: $min-width),
      only screen and (max-height: 750px) {
        width: 100%;
      }
    }
  }

  &__non-host {
    display: flex;
    align-items: center;
    max-width: 600px;

    > h2 {
      text-align: left !important;
    }

    > svg {
      font-size: 60px;
      margin-right: 60px;
      color: #264653;
    }
  }
}