@import '../../../styles/vars';

.tte-toggle {
  display: flex;
  align-items: center;

  .react-toggle:not(.react-toggle--checked) {
    .react-toggle-track {
      background: #E5E5E5;
    }

    .react-toggle-thumb {
      border-color: #E5E5E5;
    }

    &:hover {
      .react-toggle-track {
        background: darken(#E5E5E5, 5%);
      }

      .react-toggle-thumb {
        border-color: darken(#E5E5E5, 5%);
      }
    }
  }

  .react-toggle--checked {
    .react-toggle-track {
      background-color: $primary-green;
    }

    .react-toggle-thumb {
      border-color: $primary-green;
    }

    &:hover {
      .react-toggle-track {
        background-color: darken($primary-green, 5%) !important;
      }

      .react-toggle-thumb {
        border-color: darken($primary-green, 5%) !important;
      }
    }
  }

  &__label {
    margin-left: 20px;
    display: flex;
    align-items: center;
    font-size: 15px;
    flex: 1;
  }
}