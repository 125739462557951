@import '../../styles/vars';

.core-layout {
  padding: 120px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media only screen and (max-height: 750px) {
    padding-top: 100px;
  }

  @media only screen and (max-width: $mobile-width) {
    padding: 20px;
    padding-top: 100px;
  }

  &__header {
    display: flex;
    align-items: center;
    height: 120px;
    position: fixed;
    top: 0;
    z-index: 1;
    background: white;
    width: 100vw;
    left: 0;
    padding-left: 40px;
    padding-right: 40px;

    > svg {
      width: 65px;
      height: 65px;

      @media only screen and (max-width: $mobile-width) {
        width: 50px;
        height: 50px;
      }
    }

    @media only screen and (max-height: 750px) {
      height: 100px;
    }

    @media only screen and (max-width: $mobile-width) {
      height: 80px;
      padding-left: 20px;
      padding-right: 20px;
    }

    > h1 {
      margin-right: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__right {
      display: flex;
      align-items: center;
      margin-left: auto;

      &__close {
        font-size: 50px;
        cursor: pointer;
        color: $text;
      }
    }
  }

  &__content {
    flex: 1;
    display: flex;
  }
}