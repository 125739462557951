@import '../../../styles/vars';

.error {
  display: flex;
  align-items: center;
  margin: auto;

  @media only screen and (max-width: $mobile-width) {
    flex-direction: column;
    text-align: center;
  }

  > svg {
    width: 50vw;
    height: auto;
    max-width: 700px;

    @media only screen and (max-width: $mobile-width) {
     width: 100%;
    }
  }

  &__text {
    margin-left: 40px;

    @media only screen and (max-width: $mobile-width) {
      margin-left: 0;
      margin-top: 40px;
    }
  }
}