@import '../../styles/vars';

.new-meeting {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: $mobile-width) {
    flex-direction: column;
  }

  > * {
    width: 50%;
  }

  > svg {
    max-width: 520px;
    height: auto;

    @media only screen and (max-width: $mobile-width) {
      width: 100%;
      max-width: 250px;
    }
  }

  &__content {
    max-width: 400px;
    margin-left: 10vw;

    > button {
      margin-top: 80px;

      @media only screen and (max-width: $mobile-width) {
        margin-bottom: 60px;
      }
    }

    @media only screen and (max-width: $mobile-width) {
      margin-left: 0;
      width: 100%;
      margin-top: 40px;
    }

    &__block {
      margin-bottom: 40px;

      @media
      only screen and (max-height: 750px),
      only screen and (max-width: $mobile-width){
        margin-bottom: 30px;
      }

      &__step {
        display: flex;
        align-items: baseline;

        > span {
          font-size: 50px;
          font-weight: 800;
          color: $text;
          margin-left: 10px;
        }
      }
    }
  }
}