@import '../../../styles/vars';

.actions {
  display: flex;
  align-items: center;
  margin-right: -15px;

  &--mobile {
    margin-right: unset;

    &__btn {
      font-size: 30px;
      color: $text;
      border: 0;
      background: none;
      padding: 0;
      position: relative;
      cursor: pointer;
      margin-right: 20px;
      min-width: 30px;

      @media only screen and (max-width: 350px) {
        font-size: 25px;
        min-width: 25px;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    &__btn-tick {
      position: absolute;
      right: -8px;
      bottom: -1px;
      font-size: 11px;
      color: $success;
    }
  }

  > * {
    margin-right: 20px;

    &:first-child {
      > svg {
        color: $primary-green !important;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  button {
    background: none !important;

    &:disabled {
      background: none !important;
    }

    > svg {
      color: #264653 !important;
      height: 25px !important;
      width: 25px !important;
      transition: transform 0.2s ease-in-out;
    }

    &:hover > svg {
      transform: scale(1.2);
    }
  }
}