@import '../../../styles/vars';

@keyframes pulse {
  0%   {
    transform: scale(1);
  }
  50%  {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.meeting {
  flex-direction: row;
  padding-right: 0;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 0;

    &__main {
      flex: 1;
      display: flex;
      flex-direction: column;
      background: $light-grey;
      border-radius: 12px;
      position: relative;
      padding: 30px;
      margin-bottom: 40px;

      @media only screen and (max-width: 800px) {
        margin-bottom: 20px;
      }

      @media only screen and (max-width: $mobile-width) {
        width: calc(100vw - 40px);
        flex: unset;
        margin-top: auto;
        margin-bottom: auto;
        min-height: 325px;
      }

      &--results {
        @media only screen and (max-width: $mobile-width) {
          background: none;
          margin-top: 0;
          margin-bottom: 0;
          padding: 0;
        }
      }

      @media only screen and (max-height: 750px) {
        min-height: unset;
      }
    }

    > button {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__hands {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    max-width: 600px;

    > svg {
      max-width: 200px;
    }

    > svg path, > svg polyline {
      stroke: #264653;
    }
  }
}