.announcement-modal {
  display: flex;
  flex-direction: column;

  textarea {
    height: 150px;
  }

  &__save {
    margin-left: auto;
    margin-top: auto;
  }

  &__message {
    font-size: 20px;
  }

  > button {
    margin-top: 40px;
  }
}