@import '../../../styles/vars';

.team {
  &--mobile {
    flex: 1;
    display: flex;
    flex-direction: column;

    .fa-check {
      color: $success;
    }

    > button {
      margin-top: auto;
    }
  }

  &--mobile &__members-container {
    &__members {
     margin-bottom: auto;
    }
  }

  &__members-container {
    flex: 1;
    min-height: 0;

    &__members {
      height: 100%;
      overflow-y: auto;
    }
  }

  > button {
    margin-top: auto;

    @media only screen and (min-width: $mobile-width + 1) {
      display: none;
    }
  }

  > h2 {
    margin-bottom: 40px;
  }

  &__member {
    display: flex;
    margin-bottom: 20px;
    align-items: center;

    > p {
      margin-right: 40px;
      text-overflow: ellipsis;
      overflow: hidden;

      > svg {
        color: $primary-yellow;
        margin-left: 10px;
      }
    }

    > *:last-child:not(:first-child) {
      margin-left: auto;
    }
  }
}