.new-feature-wrapper {
  display: inline-flex;

  &__tooltip {
    padding: 15px;

    .text {
      color: white;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      > svg {
        cursor: pointer;
        font-size: 20px;
        color: white;
        pointer-events: auto;
      }
    }
  }
}
