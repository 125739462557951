@import '../../../styles/vars';

.task-modal {
  display: flex;
  flex-direction: column;

  &__link-btn {
    width: 50%;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    background: #0065FF;

    &:hover {
      background: lighten(#0065FF, 5%);
    }

    &:focus {
      background: #0065FF;
    }

    @media only screen and (max-width: $mobile-width) {
      width: 100%;
    }
  }

  > button {
    margin-left: auto;
  }

  > *:not(:last-child) {
    margin-bottom: 30px;
  }

  &__option {
    display: flex;
    align-items: center;

    > span:first-of-type {
      white-space: nowrap;
      margin-right: 15px;
    }

    &__icon {
      width: 15px;
      height: 15px;
      margin-right: 10px;
    }
  }

  &__error {
    display: flex;
    align-items: center;
    background: $light-grey;
    border-radius: 12px;
    padding: 20px;

    > svg {
      color: $danger;
    }
  }
}