@import '../../../styles/vars';

.chat {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1px;

  @media only screen and (max-width: $mobile-width) {
    padding: 0;
  }

  &--empty {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1px 10px;

    > .text {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  &__announcement {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 20px 15px;
    margin-top: 15px;
    margin-bottom: 25px;
    //margin-left: 12px;

    > svg {
      font-size: 18px;
    }

    > .text {
      margin-left: 15px;
    }
  }

  &__comments-container {
    min-height: 0;
    flex: 1;
    margin-bottom: 20px;
  }

  &__comments {
    height: 100%;
    overflow-y: auto;

    // Some scrollbar hocus pocus
    padding-right: 20px;
    width: calc(100% + 20px);
    margin-right: -20px;

    > *:first-child {
      margin-top: 0;
    }
  }

  &__comment {
    display: flex;

    &:last-of-type &__user {
      &__separator {
        display: none !important;
      }
    }

    &__user {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 3px;

      > span {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        min-height: 30px;
        min-width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        font-size: 13px;
        font-weight: 600;
        user-select: none;
      }

      &__separator {
        width: 2px;
        border-left: 4px dotted #E5E5E5;
        flex: 1;
      }
    }

    &__inline {
      margin-left: 10px;
      width: 100%;
      padding-right: 1px;

      .chat__comment__right {
        padding-bottom: 30px;
      }

      > .chat__comment {
        .chat__comment__right {
          padding-bottom: 20px;
        }
      }

      > .input-continue {
        margin-bottom: 20px;
      }
    }

   &__right {
     padding-bottom: 10px;

     + .chat__comment {
       margin-top: -10px;
     }

     > p {
       //padding-top: 10px;
       padding-right: 25px;
     }

     &__edit {
       > p {
         font-size: 12px;
       }
     }

     &__header {
       display: flex;
       justify-content: space-between;

       > *:first-child {
         line-height: 18px;
         margin-bottom: 2px;
       }

       &__menu-btn {
         margin-left: auto;
         font-size: 16px;
         border: 0;
         padding: 0;
         background: none;
         cursor: pointer;
         color: $text;

         @media only screen and (max-width: $mobile-width) {
           font-size: 25px;
         }
       }
     }

     &__footer {
       display: flex;
       align-items: center;
       margin-top: 2px;

       > a {
         font-size: 12px;
         margin-left: 10px;
       }
     }
   }
  }
}