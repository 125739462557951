@import '../../../styles/vars';

.info-tooltip {
  color: $primary-green;
  font-size: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: $mobile-width) {
    font-size: 25px;
    height: 25px;
  }
}