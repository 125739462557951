@import '../../../../styles/vars';

.radio-buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media only screen and (max-width: $mobile-width) {
    flex-direction: column;
  }

  &__option {
    width: 135px;
    height: 135px;
    min-height: 135px;
    min-width: 135px;
    border-radius: 12px;
    padding: 0;
    border: 1px solid #CBCCCC;
    cursor: pointer;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: $mobile-width) {
      width: 100%;
      height: 50px;
      min-height: 50px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-left: 30px;
      padding-right: 30px;

      &--selected {
        border-width: 2px;
      }

      &:not(:last-of-type) {
        margin-bottom: 15px;
      }

      &__label {
        margin-bottom: 0 !important;
      }
    }

    &:hover {
      border-color: $primary-green;
    }

    &--selected {
      border: 3px solid $primary-green;
      pointer-events: none;
    }

    &--disabled {
      opacity: 50%;
      pointer-events: none;
    }
  }
}