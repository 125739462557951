@import '../../styles/vars';

.name-page {
  flex: 1;
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;

  &__footer {
    background: $light-grey;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 150px 40px;

    @media only screen and (max-width: $mobile-width) {
      padding: 40px;
    }

    &__copyright.text {
      font-size: 12px !important;

      @media only screen and (max-width: $mobile-width) {
        text-align: center;
      }
    }

    > svg {
      width: 100px;
      height: 100px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 100px;

      @media only screen and (max-width: $mobile-width) {
        width: 50px;
        height: 50px;
        margin-bottom: 40px;
      }
    }

    &__content {
      width: 100%;
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;

      &__list {
        display: flex;
        align-items: center;

        svg {
          @media only screen and (max-width: $mobile-width) {
            width: auto;
            height: 25px;
          }
        }

        > * {
          color: $text;
        }

        > *:not(:last-child) {
          margin-right: 20px;

          @media only screen and (max-width: $mobile-width) {
            margin-right: 10px;
          }
        }
      }
    }
  }

  &__banner {
    background: $primary-green;
    border: none;
    clip-path: ellipse(75% 100% at center top);
    height: auto;
    min-height: 370px;
    display: flex;
    padding: 150px 40px;

    @media only screen and (max-width: $mobile-width) {
      padding: 40px;
      clip-path: ellipse(100% 100% at center top);
    }

    &__get-started {
      align-items: center;
      width: fit-content;
      margin: auto;

      > .text {
        color: white;
        margin-bottom: 40px;
        text-align: center;
      }

      > button {
        background: white;
        color: $primary-green;
        margin-left: auto;
        margin-right: auto;
        transition: transform 0.2s ease-in-out;

        @media only screen and (max-width: $mobile-width) {
          width: 100%;
        }

        &:hover {
          background: white;
          color: $primary-green;
          transform: scale(1.1);
        }

        &:focus {
          background: white;
          color: $primary-green;
        }
      }
    }
  }

  &__banner-container {
    background: $light-grey;
  }

  &__getting-started {
    display: flex;
    flex-direction: column;
    max-width: 1500px;
    width: 100%;
    padding: 150px 40px;

    @media only screen and (max-width: $mobile-width) {
      padding: 40px;
    }

    > .text {
      margin-bottom: 80px;

      @media only screen and (max-width: $mobile-width) {
        margin-bottom: 40px;
      }
    }

    &__block {
      max-width: 350px;

      .text {
        font-size: 18px !important;

        @media only screen and (max-width: $mobile-width) {
          font-size: 15px !important;
        }
      }

      &__number {
        background: $primary-green;
        color: white;
        font-weight: 800;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      > *:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    
    &__container {
      display: flex;
      width: 100%;
      justify-content: space-evenly;

      @media only screen and (max-width: $mobile-width) {
       flex-direction: column;

        > *:not(last-child) {
          margin-bottom: 60px;
        }
      }
    }
  }

  &__logo {
    width: 60vw;
    max-width: 400px;
    margin-bottom: 5vh;
  }

  &__hero {
    font-size: 70px;
    line-height: 70px;

    @media only screen and (max-width: $mobile-width) {
      font-size: 30px;
      line-height: 30px;
    }
  }

  &__hero-subtitle.text {
    font-size: 18px !important;

    @media only screen and (max-width: $mobile-width) {
      font-size: 15px !important;
    }
  }

  &__features {
    padding: 150px 40px 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: $light-grey;

    @media only screen and (max-width: $mobile-width) {
      padding: 0px 40px 50px;
    }

    &__container {
      max-width: 1800px;
      margin-left: auto;
      margin-right: auto;
    }

    &__feature {
      display: flex;
      align-items: center;
      padding-top: 50px;
      padding-bottom: 50px;
      max-width: 1500px;

      > svg {
        width: 50vw;
        height: auto;
        max-width: 400px;
        display: none;
      }

      &__content {
        margin-left: 40px;
        display: flex;
        flex-direction: column;

        > .text {
          font-size: 18px !important;
        }

        @media only screen and (max-width: $mobile-width) {
          margin-left: 0;

          > .text {
            font-size: 15px !important;
          }
        }

        &__title {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          > svg {
            margin-right: 15px;
            font-size: 25px;
            color: $text;
          }
        }
      }

      @media only screen and (min-width: $mobile-width + 1) {
        > svg {
          display: block;
        }

        &:nth-of-type(even)  {
          flex-direction: row-reverse;
        }

        &:nth-of-type(even) &__content {
          margin-left: 0;
          margin-right: 40px;

          &__title {
            margin-left: auto;
          }

          > p {
            text-align: right;
          }
        }
      }
    }
  }

  &__content {
    margin: auto;
    max-width: 1800px;
    min-height: 100vh;
    padding: 40px;
    display: flex;
    flex-direction: column;

    > svg {
      color: $text;
      margin-left: auto;
      margin-right: auto;

      @media only screen and (max-height: 1000px) {
        display: none;
      }
    }

    &__columns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;

      @media only screen and (max-width: $mobile-width) {
       flex-direction: column;
      }
    }

    &__left {
      max-width: 600px;
      margin-right: 5vw;

      @media only screen and (max-width: $mobile-width) {
        margin-right: 0vw;
        max-width: unset;
      }

      input {
        text-align: left;
      }

      > * {
        &:not(:last-child) {
          margin-bottom: 7vh;
        }
      }
    }

    &__right {
      > svg {
        width: 100%;
      }

      @media only screen and (max-width: $mobile-width) {
        margin-bottom: auto;
        margin-top: 80px;

        > svg {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}