@import '../../../styles/vars';

$min-width: 1340px;

.stats {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  height: 220px;

  @media only screen and (max-width: $min-width) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    height: unset;
    margin-top: auto;
    margin-bottom: auto;
  }

  > svg {
    width: auto;
    height: 150px;

    @media only screen and (max-width: $min-width) {
      display: none;
    }
  }

  &__stat {
    display: flex;
    align-items: center;
    height: 30px;

    @media only screen and (max-width: $min-width) {
      width: 100%;
    }

    &__text {
      display: flex;
      align-items: baseline;

      @media only screen and (max-width: $min-width) {
        width: 100%;
      }

      > h1 {
        line-height: 30px;
        margin-left: 10px;

        @media only screen and (max-width: $min-width) {
          margin-left: auto;
        }
      }
    }

    @media only screen and (max-width: $min-width) {
      height: 30px;
    }

    &:not(:last-of-type) {
      @media only screen and (max-width: $min-width) {
       margin-bottom: 20px;
      }

      @media only screen and (max-height: 720px) {
        margin-bottom: 10px;
      }

      &:after {
        height: 100%;
        width: 1px;
        content: '';
        background: #e5e5e5;
        margin-right: 30px;
        margin-left: 30px;

        @media only screen and (max-width: $min-width) {
         display: none;
        }
      }
    }
  }

  &__right {
    margin-left: 60px;

    @media only screen and (max-width: 1150px) {
      margin-left: 0;
    }

    @media only screen and (max-width: $min-width) {
      margin-left: 0;
      width: 100%;

      > button {
        width: 100%;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__text {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      @media only screen and (max-width: $min-width) {
        flex-direction: column;
        align-items: unset;
      }

      @media only screen and (max-height: 720px) {
        margin-bottom: 15px;
      }
    }
  }
}