$primary-green: #00A08F;
$primary-yellow: #F4C51B;

$danger: #FF453A;
$success: #33C759;

$text: #264653;

$light-grey: #F5F5F5;
$dark-grey: #A6A6A6;


// Queries

$mobile-width: 700px;