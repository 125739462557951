@import '../../styles/vars';

.landing {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__columns {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;

    @media only screen and (max-width: $mobile-width) {
      flex-direction: column;
    }

    &__divider {
      width: 1px;
      height: 100%;
      background: #eee;
      margin-left: 5vw;
      margin-right: 5vw;

      @media only screen and (max-width: $mobile-width) {
        width: 100%;
        height: 1px;
        margin: 60px 0 60px 0;
      }
    }

    &__column {
      width: 50%;
      max-width: 520px;

      @media only screen and (max-width: $mobile-width) {
        width: 100%;
        max-width: unset;
        text-align: center;
      }

      > svg {
        height: 25vh;
        width: 100%;

        @media only screen and (max-width: $mobile-width) {
          max-width: unset;
          height: auto;
        }
      }

      &__subtitle {
        height: 50px;
      }
    }
  }
}