@import '../../../styles/vars';

.results {
  margin-top: auto;
  margin-bottom: auto;

  &--mobile {
    width: 100vw;
    margin-left: -20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    
    &__dots {
      display: grid;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      grid-gap: 10px;
      grid-template-columns: repeat(auto-fit, 20px);
      grid-auto-rows: auto;
      width: 100%;
      justify-content: center;

      &__dot {
        width: 17px;
        height: 17px;
        background: #E5E5E5;
        border-radius: 50%;
        border: 0;
        padding: 0;

        &--selected {
          background: $primary-green;
          pointer-events: none;
        }
      }
    }

    &__page {
      width: 100%;
      height: 280px;
      padding-left: 30px;
      padding-right: 30px;

      &__member {
        height: 80px;
        background: $light-grey;
        width: 100%;
        border-radius: 12px;
        display: flex;
        align-items: center;
        padding-left: 15px;
        padding-right: 20px;

        .fa-times {
          padding-left: 5px;
        }

        .meeting__hands {
          padding-bottom: 5px;

          > svg {
            height: 40px;
            width: auto;
          }
        }

        &:not(:last-of-type) {
          margin-bottom: 20px;
        }
      }
    }
  }

  > h2 {
    position: absolute;
    top: 30px;
    left: 30px;
  }

  &__members {
    display: grid;
    grid-template-columns: repeat(auto-fit, 240px);
    grid-auto-rows: auto;
    grid-gap: 40px;
    justify-content: center;
    padding-top: 60px;
    padding-bottom: 60px;

    &__member {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 100px;
      padding: 20px;
      border-radius: 12px;
      color: $text;
      background: white;
      width: 240px;
      height: 240px;

      > .meeting__hands {
        > svg {
          width: 40%;
        }
      }

      &__no-estimate {
        color: $danger;
        font-size: 120px;
        font-weight: 600;
        margin-top: auto;
        margin-bottom: auto;
        height: 60px;

        @media only screen and (max-width: $mobile-width) {
          height: 40%;
          width: auto !important;
        }
      }

      > p {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        text-align: center;
        font-weight: 800;
        margin-top: 30px;
      }
    }
  }
}