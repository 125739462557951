@import '../../../styles/vars';

.tte-button {
  background: $primary-green;
  color: white;
  border: 0;
  height: 50px;
  min-height: 50px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 15px;
  width: fit-content;
  padding-left: 40px;
  padding-right: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    margin-right: 10px;
    font-size: 25px;
  }

  &:focus {
    color: white;
  }

  &:hover {
    color: white;
    background: lighten($primary-green, 5%);
  }

  &:disabled {
    background-color: $primary-green !important;
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }

  &--full-width {
    width: 100%;
  }

  &--icon {
    width: 50px;
    min-width: 50px;
    padding: 0;

    > svg {
      font-size: 20px;
      margin-right: 0;
    }
  }

  &--busy {
    > svg {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--danger {
    background: $danger;

    &:hover {
      background: lighten($danger, 5%);
    }
  }
}