.nudge-button {
  &--minimised {
    position: relative;

    &__timeout {
      position: absolute;
      bottom: 2px;
      right: -2px;
      font-weight: 800;
      opacity: 0.5;
      user-select: none;
      width: 16px;
    }
  }
}