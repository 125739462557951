@import '~bulma/bulma';
@import url('https://fonts.googleapis.com/css?family=DM+Sans:300,400,700,900&display=swap');
@import './styles/_vars.scss';
@import './styles/_utils.scss';
@import '~react-toggle/style.css';
@import '~noty/lib/noty.css';
@import '~noty/lib/themes/metroui.css';
@import '~animate.css/animate.css';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html,
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'DM Sans';
  outline-color: lighten($primary-green, 10%);
}

h1,
h2,
h3,
h4,
h5,
p,
li,
strong {
  color: $text;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 800;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
  font-size: 12px !important;
  color: $text !important;
  font-weight: 400 !important;
  max-width: 400px;

  @media only screen and (max-width: 420px) {
    max-width: calc(100vw - 20px);
  }
}

.szh-menu {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 12px;

  &__item {
    height: 50px;

    > svg {
      width: 15px !important;
      height: auto;
      color: $text;
    }

    &--active {
      background-color: $primary-green !important;
    }
  }
}

a {
  color: $primary-green;

  &:hover {
    color: $primary-green;
    text-decoration: underline;
  }
}

[data-whatintent='mouse'] *:focus,
[data-whatintent='touch'] *:focus {
  outline: none;
}

// React-select menu z index
body > div[class*="css-"] {
  z-index: 9999;
}

