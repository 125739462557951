@import '../../../styles/vars';

.tte-tabs {
  display: flex;
  flex-direction: column;
  width: 340px;
  overflow: hidden;
  position: fixed;
  right: 0;
  min-width: 340px;
  height: calc(100% - 160px);

  @media only screen and (max-height: 750px) {
    height: calc(100% - 140px);
  }

  @media only screen and (min-width: $mobile-width + 1) and (max-width: 1000px) {
    width: 290px;
    min-width: 290px;
  }

  &--spacer {
    width: 300px;
    min-width: 300px;

    @media only screen and (min-width: $mobile-width + 1) and (max-width: 1000px) {
      width: 250px;
      min-width: 250px;
    }
  }

  &__header {
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    position: relative;

    &__slider {
      position: absolute;
      bottom: 0;
      height: 2px;
      background: $text;
      transition: left 0.2s ease-in-out;
    }

    &__tab {
      padding: 0 15px 15px;
      border-radius: 6px 6px 0 0;
      font-size: 15px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      border-bottom: 2px solid $light-grey;
      background: 0;
      white-space: nowrap;
      color: $dark-grey;
      transition: color 0.2s ease-in-out;

      > span {
        @media only screen and (max-width: 1000px) {
          display: none;
        }
      }

      &:not(&--selected) {
        cursor: pointer;
      }

      > svg {
        font-size: 15px;
        margin-right: 10px;

        @media only screen and (max-width: 1000px) {
          font-size: 20px;
          margin-right: 0;
        }
      }

      &--selected {
        background: white;
        color: $text;
      }
    }
  }

  &__content {
    flex: 1;
    min-height: 0;
    padding-left: 40px;
    padding-right: 40px;
  }
}
