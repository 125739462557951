@import '../../../styles/vars';

$header-height: 90px;
$header-height-mobile: 70px;
$small-mobile-width: 600px;

.actionsheet {
  height: 100%;
  background: white;

  &__header {
    display: flex;
    background: white;
    height: $header-height;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: $small-mobile-width) {
      padding-left: 30px;
      padding-right: 30px;
      height: $header-height-mobile;

      &__handle {
        width: 20%;
        height: 7px;
        border-radius: 12px;
        background: $light-grey;
        position: absolute;
      }
    }

    &__close {
      margin-left: auto;
      cursor: pointer;
    }
  }

  &__content {
    padding: 0px 50px 50px;
    height: calc(100% - #{$header-height});
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $small-mobile-width) {
      height: calc(100% - #{$header-height-mobile});
      padding: 0px 30px 30px;
    }
  }

  &__close {
    margin-left: auto;
  }
}