@import '../../../styles/vars';

@mixin text {
  color: $text;
  font-weight: 400;
}

@mixin header {
  font-weight: 800;
}

h1.text {
  @include header;

  font-size: 30px;

  @media only screen and (max-width: $mobile-width) {
    font-size: 20px;
  }
}

h2.text {
  @include header;

  font-size: 24px;

  @media only screen and (max-width: $mobile-width) {
    font-size: 18px;
  }
}

p.text {
  font-size: 15px;
}

.text {
  @include text;

  &--danger {
    color: $danger;
  }

  &--bold {
    font-weight: 800;
  }

  &--small {
    font-size: 12px !important;
  }
}